
<template>
	<div>
		<a-row type="flex" :gutter="[24, 24]" style="margin-top: 25px;">
			<a-col :span="16">
				<h5 class="font-semibold">Meus conteúdos</h5>
				<p class="text-lg">
				</p>
				<p style="margin-top: 55px;"></p>
			</a-col>
		</a-row>
		<!-- Project Cards -->
		<a-row type="flex" :gutter="24">

			<a-col :xs="24" :sm="12" :md="8" :lg="8" class="mb-24">

				<router-link to="/productor/contents/new">
					<a-card class="crm-bar-line header-solid h-full"
						:bodyStyle="{ padding: '15px 0 15px 0', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', heightMin: '350px' }">
						<a href="" class="text-center text-muted font-bold">
							<h3 class="font-semibold text-muted mb-0">+</h3>
							<h5 class="font-semibold text-muted">Publicar Conteúdo</h5>
						</a>
					</a-card>
				</router-link>

			</a-col>

			<a-col v-if="isLoadindContents" :xs="24" :sm="12" :md="8" :lg="8" class="mb-24">
			<template>
      <!-- Skeleton Loading -->
				<div class="skeleton-loading"></div>
				</template>
			</a-col>

			<a-col v-if="isLoadindContents" :xs="24" :sm="12" :md="8" :lg="8" class="mb-24">
			<template>
      <!-- Skeleton Loading -->
				<div class="skeleton-loading"></div>
				</template>
			</a-col>

			<a-col v-for="(content, index) in contents" :key="index" :xs="24" :sm="12" :md="8" :lg="8" class="mb-24">
				<CardContent :id="content.type" :title="content.title" :content="content.description"
					:cover="content.banner"
					:team="['images/face-1.jpg', 'images/face-2.jpg', 'images/face-3.jpg', 'images/face-4.jpg', 'images/face-5.jpeg',]">
				</CardContent>
			</a-col>

		</a-row>
		<!-- / Project Cards -->

	</div>
</template>

<script>

import CardProject2 from "../../components/Cards/CardProject2"
import CardContent from "../../components/Cards/CardContent"

export default ({
	components: {
		CardProject2,
		CardContent,
	},
	data() {
		return {
			isLoadindContents: true,
			// Active button for the "User Profile" card's radio button group.
			contents: null,
			profileHeaderBtns: 'overview',
		}
	},
	methods: {
		/* OBTER A LISTA DE PRODUTOS */
		async getContents() {


			const endpoints = [
				'https://ms-league-az.kemosoft.com.br/product/all',
				'https://ms-league-az.kemosoft.com.br/course/all',
				'https://ms-league-az.kemosoft.com.br/event/all'
			];

			try {
				const [products, courses, events] = await Promise.all(endpoints.map(endpoint => this.$api.dataService.getData(endpoint)));

				const formattedData = [];

				// Format products
				formattedData.push(...products.map((product) => ({
					id: product.id,
					title: product.name,
					description: product.description,
					banner: product.banner ? product.banner['1x1'] : null,
					logo: product.logo,
					type: 'Product',
				})));

				// Format courses
				formattedData.push(...courses.map((course) => ({
					id: course.id,
					title: course.title,
					description: course.description,
					banner: course.banner ? course.banner['1x1'] : null,
					logo: course.logo,
					type: 'Course',
				})));

				// Format events
				formattedData.push(...events.map((event) => ({
					id: event.id,
					title: event.name,
					description: event.description,
					banner: event.banner ? event.banner['1x1'] : null,
					logo: event.logo,
					type: 'Event',
				})));

				this.isLoadindContents = false;
				this.contents = formattedData;
			} catch (error) {
				console.error('Error:', error.message);
			}
		},

	},
	mounted() {
		this.getContents()
	}
})
</script>

<style lang="scss">

.skeleton-loading {
  width: 100%;
  height: 150px; /* Ajuste a altura conforme necessário */
  background: linear-gradient(90deg, #0e1117 25%, #10161d 50%, #1a232e 75%);
  background-size: 200% 100%;
  animation: loading 3.5s infinite;
  border-radius: 10px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

</style>